import { usePromo as getPromo, promoStart, promoEnd } from "../../utils/usePromo";
import {useAbTest as getAbTest}  from '../../utils/useAbTest'
import getIsLifemart from "../Lifemart/hooks/useIsLifemart";
import getIsMilitary from "../Military/hooks/useIsMilitary";

export const comparisonInfo = (hubblePricing) => {
  const isPromo = getPromo(promoStart, promoEnd)
  const isPaidShipping = getAbTest()
  const isLifemart = getIsLifemart()
  const isMilitary = getIsMilitary()

  return(
    {
      price: {
        classic: isPaidShipping ?
          `<p><strike>${!isLifemart && !isMilitary ? "$22.99/eye" : "$19.99/eye"}</strike> <b>$1.00*</b><br/>10-day starter pack</p>` :
          `<p><strike>${!isLifemart && !isMilitary ? "$22.99/eye" : "$19.99/eye"}</strike> <b>$1.00</b><br/>10-day starter pack</p>`,
        hydro: isPromo ?
          `<p><strike>$${hubblePricing.hydro.subPrice}/eye</strike> <b>$${hubblePricing.hydro.firstPrice}/eye</b></p> <p class='limited-time-text'>Limited Time Only</p>` :
          isPaidShipping ?
          `<p><strike>$${hubblePricing.hydro.subPrice}/eye</strike> <b>$${hubblePricing.hydro.firstPrice}/eye*</b></p> ` :
          `<p><strike>$${hubblePricing.hydro.subPrice}/eye</strike> \n<b>$${hubblePricing.hydro.firstPrice}/eye</b></p>`,
        skyhy: isPromo ?
          `<p><strike>$${hubblePricing.skyhy.subPrice}/eye</strike> <b>$${hubblePricing.skyhy.firstPrice}/eye</b></p> <p class='limited-time-text'>Limited Time Only</p>` :
          isPaidShipping ?
          `<p><strike>$${hubblePricing.skyhy.subPrice}/eye</strike> <b>$${hubblePricing.skyhy.firstPrice}/eye*</b></p>` :
          `<p><strike>$${hubblePricing.skyhy.subPrice}/eye</strike> \n<b>$${hubblePricing.skyhy.firstPrice}/eye</b></p>`,
        skyhyMonthly: isPromo ?
          `<p><strike>$${hubblePricing.skyhyMonthly.subPrice}/eye</strike> <b>$${hubblePricing.skyhyMonthly.firstPrice}/eye</b></p> <p class='limited-time-text'>Limited Time Only</p>` :
          isPaidShipping ?
            `<p><strike>$${hubblePricing.skyhyMonthly.subPrice}/eye</strike> <b>$${hubblePricing.skyhyMonthly.firstPrice}/eye*</b></p>` :
            `<p><strike>$${hubblePricing.skyhyMonthly.subPricePerMonth}/eye</strike> <b>$${hubblePricing.skyhyMonthly.firstPricePerMonth}/eye</b><br/>(cost per month)</p>`,
      },
      pack: {
        classic: "<b>30 lenses</b> <br\>1-month supply",
        hydro: "<b>30 lenses</b> <br/>1-month supply",
        skyhy: "<b>30 lenses</b> <br/>1-month supply",
        skyhyMonthly: "<b>6 lenses</b> <br/>6-month supply",
      },
      daily: {
        classic: true,
        hydro: true,
        skyhy: true,
        skyhyMonthly: false,
      },
      monthly: {
        classic: false,
        hydro: false,
        skyhy: false,
        skyhyMonthly: true,
      },
      personalized: {
        classic: true,
        hydro: true,
        skyhy: true,
        skyhyMonthly: true,
      },
      eco: {
        classic: true,
        hydro: true,
        skyhy: true,
        skyhyMonthly: true,
      },
      natural: {
        classic: false,
        hydro: true,
        skyhy: true,
        skyhyMonthly: true,
      },
      blink: {
        classic: false,
        hydro: true,
        skyhy: true,
        skyhyMonthly: true,
      },
      world: {
        classic: false,
        hydro: true,
        skyhy: false,
        skyhyMonthly: false,
      },
      touch: {
        classic: false,
        hydro: true,
        skyhy: false,
        skyhyMonthly: false,
      },
      high_oxygen: {
        classic: false,
        hydro: false,
        skyhy: true,
        skyhyMonthly: true,
      },
      flex_wear: {
        classic: false,
        hydro: false,
        skyhy: true,
        skyhyMonthly: true,
      },
      top_choise: {
        classic: false,
        hydro: false,
        skyhy: true,
        skyhyMonthly: true,
      },
      fda: {
        classic: true,
        hydro: true,
        skyhy: true,
        skyhyMonthly: true,
      },
    }
  )
}

export const labels = {
  pack: "pack size",
  price: "first order price",
  fda: "Fda-approved",
  personalized: "personalized delivery cadence",
  daily: {text: "daily disposables", sub: 'Keep your eyes healthy with fresh lenses everyday'},
  monthly: {text: "monthly disposable", sub: 'An affordable way to benefit from  the latest lens technology'},
  eco: {text: "Packaging made from recyclable material"},
  natural: {text: "Natural Eye Hydration", sub: "Material and shape are specially designed to retain moisture on the lens surface"},
  blink: {text: "Blink Comfort Design", sub: "Lens design minimizes discomfort when blinking"},
  world: {text: "World's slimmest lens packaging", sub: "Less plastic, less space, more convenient"},
  touch: {text: "Hygienic Touch Technology", sub: "Packaged so that your fingers never touch the inside of the lens"},
  high_oxygen: {text: "High Oxygen PERMEABILITY", sub: "Cutting-edge silicone hydrogel material allows oxygen to move through the lens for fresh eyes all day"},
  flex_wear: {text: "Soft flex wear", sub: "Flexible construction allows lenses to mold to the curve of your eye, fitting snugly and comfortably over the surface"},
  top_choise: {text: "Doctors’ top choice", sub: "7 out of 10 new prescriptions are for lenses made of SkyHy's material (silicone hydrogel)"},
}

export const contactLenses = (isClassicNewPrice, hubblePricing) => {
  return(
    [
      isClassicNewPrice
      ? {title: "Hubble<sup>®</sup> Classic Daily", feature: "affordable dailies", type: "classic", product: "Classic", price: "<b>$1.00</b> <br/><span class='weak'>10-day starter pack</span>", originalPrice: "$22.99/eye", packSize: 30, link: "/contact-lenses/classic-by-hubble-lenses", cadence: 28}
      : {title: "Hubble<sup>®</sup> Classic Daily", feature: "affordable dailies", type: "classic", product: "Classic", price: "<b>$1.00</b> <br/><span class='weak'>10-day starter pack</span>", originalPrice: "$19.99/eye", packSize: 30, link: "/contact-lenses/classic-by-hubble-lenses", cadence: 28},
      {title: "Hydro by Hubble<sup>®</sup> Daily", feature: "naturally hydrating", type: "hydro", product: "Hydro", price: `$${hubblePricing.hydro.firstPrice}/eye`, originalPrice: `$${hubblePricing.hydro.subPrice}/eye`, tagText:'popular choice', tagColor: 'purple', packSize: 30, link: "/contact-lenses/hydro-by-hubble-lenses", percent_off: hubblePricing.hydro.percentOff, cadence: 28},
      {title: "SkyHy by Hubble<sup>®</sup> Daily", feature: "most breathable", type: "skyhy", product: "Skyhy", price: `$${hubblePricing.skyhy.firstPrice}/eye`, originalPrice: `$${hubblePricing.skyhy.subPrice}/eye`, tagText:'premium pick', tagColor: 'orange', packSize: 30, circleTagText: "Winter Sale", circleTagColor: "winter", link: "/contact-lenses/skyhy-by-hubble-lenses", percent_off: hubblePricing.skyhy.percentOff, cadence: 28},
      {title: "SkyHy by Hubble<sup>®</sup> Monthly", feature: "month-long comfort", type: "skyhyMonthly", product: "SkyHy-Monthly", price: `$${hubblePricing.skyhyMonthly.firstPricePerMonth}/eye`, originalPrice: `$${hubblePricing.skyhyMonthly.subPricePerMonth}/eye`, tagText:'New!', tagColor: 'charged-blue', packSize: 6, circleTagText: "Winter Sale", circleTagColor: "winter", link: "/contact-lenses/skyhy-monthly-by-hubble-lenses", percent_off: hubblePricing.skyhyMonthly.percentOff, cadence: 168, monthPriceDisclaimer: true},
    ]
  )
}
